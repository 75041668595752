<template>
  <div dfg class="sideBarContainer">
    <el-menu
      ref="sideMenu"
      class="el-menu-vertical-demo menuContainer"
      background-color="#fff"
      text-color="#333"
      active-text-color="#1975FF"
      unique-opened
      router
      :default-active="currentMenu"
    >
      <el-submenu
        v-for="(main, mainIndex) in menuList"
        :key="mainIndex"
        :index="'main-' + mainIndex"
        v-auth="main.auth"
      >
        <template slot="title">
          <bm-icon :icon-class="main.icon" :style="{ color: main.color }"></bm-icon>
          <span>{{ main.name }}</span>
        </template>
        <template v-for="(sub, subIndex) in main.children">
          <el-submenu
            v-if="sub.children && sub.children.length"
            :key="'sub-' + subIndex"
            :index="sub.path"
            class="subMenuList"
            style="padding-left: 0"
          >
            <template slot="title">
              {{ sub.name }}
            </template>
            <el-menu-item
              v-for="(third, thirdIndex) in sub.children"
              :key="'third-' + thirdIndex"
              :index="third.path"
              v-auth="third.auth"
              >{{ third.name }}</el-menu-item
            >
          </el-submenu>
          <template v-else>
            <el-menu-item :key="'sub-' + subIndex" :index="sub.path" v-auth="sub.auth">
              {{ sub.name }}
            </el-menu-item>
          </template>
        </template>
      </el-submenu>
    </el-menu>
    <div class="footer">
      <comVersion></comVersion>
    </div>
  </div>
</template>

<script>
import comVersion from '@/component/version'
export default {
  components: { comVersion },
  data() {
    return {
      menuList: [
        {
          name: '商城管理',
          color: '#e71414',
          icon: 'iconlogo',
          auth: 'mallManage,mallData,decoration,districtManage,tanchiLeaderboard',
          children: [
            {
              name: '商城数据',
              auth: 'mallData',
              path: '/malls/overview'
            },
            {
              name: '商城装修',
              auth: 'decoration',
              path: '/malls/fitUp'
            },
            {
              name: '商圈管理',
              auth: 'districtManage',
              path: '/malls/district'
            },
            {
              name: '贪吃榜',
              auth: 'tanchiLeaderboard',
              path: '/malls/tanchiLeaderboard'
            }
          ]
        },
        {
          name: '商家中心',
          color: '#779dff',
          icon: 'iconvendor',
          auth: 'vendorManage,vendorList,vendorCategory',
          children: [
            {
              name: '商家列表',
              auth: 'vendorList',
              path: '/vendor/vendorMange/list'
            },
            {
              name: '商家分类',
              auth: 'vendorCategory,vendorCategoryList,vendorCategoryAdd,vendorCategoryEdit,vendorCategoryDelete',
              path: '/vendor/classify'
            }
          ]
        },
        {
          name: '商品管理',
          color: '#ff6c66',
          icon: 'iconproduct',
          auth: 'productManage,productList,normalProductList,commentList,productCategory,productActivity',
          path: '/product',
          children: [
            {
              name: '商品列表',
              auth: 'productList,normalProductList',
              path: '/product/partnerProduct/normalProductlist/list'
            },
            {
              name: '外接商品',
              auth: 'partnerProduct,productList',
              path: '/product/partnerProduct',
              children: [
                {
                  name: '导码商品',
                  auth: 'getCheckCodePool',
                  path: '/product/partnerProduct/list'
                },
                {
                  name: '核销码池',
                  auth: 'checkCodeList',
                  path: '/product/partnerProduct/checkCode/list'
                },
                {
                  name: '无码商品',
                  auth: 'codelessProduct',
                  path: '/product/partnerProduct/codelessProduct/list'
                }
              ]
            },
            {
              name: '物流商品',
              auth: 'expressProductList',
              path: '/product/partnerProduct/expressProductList/list'
            },
            {
              name: '限时商品',
              auth: 'seckillProductList',
              path: '/product/partnerProduct/seckillProductList/list'
            },
            {
              name: '商品评价',
              auth: 'commentList',
              path: '/product/comment',
              children: [
                {
                  name: '核销商品评价',
                  auth: 'userReviewPageBy',
                  path: '/product/comment/checkCode'
                },
                {
                  name: '物流商品评价',
                  auth: 'expressComment',
                  path: '/product/comment/express'
                }
              ]
            },
            {
              name: '商品分类',
              auth: 'productCategory',
              path: '/product/classify'
            },
            {
              name: '商品活动',
              auth: 'productActivity',
              path: '/product/activity'
            }
          ]
        },
        {
          name: '运营活动',
          color: '#3d89ff',
          icon: 'iconhuabanfuben',
          auth: 'operation,lottery',
          children: [
            {
              name: '霸王餐列表',
              auth: 'lottery',
              path: '/lottery/list'
            },
            {
              name: '限时秒杀',
              auth: 'lottery',
              path: '/seckill/list'
            }
          ]
        },
        {
          name: '订单管理',
          color: '#ff6153',
          icon: 'iconorder',
          auth: 'orderManage',
          children: [
            {
              name: '订单列表',
              auth: 'orderManage',
              path: '/order/list'
            },
            {
              name: '物流订单',
              auth: 'orderManage',
              path: '/order/express'
            }
          ]
        },

        // {
        //   name: '分销管理',
        //   color: '#ffa100',
        //   icon: 'icondistribution',
        //   color: '#ffa000',
        //   auth: 'distributionManage',
        //   children: [
        //     {
        //       name: '分销员管理',
        //       auth: 'distributionMemberPageBy',
        //       path: '/distribution/distributorMan'
        //     },
        //     {
        //       name: '分销规则',
        //       auth: 'distribution',
        //       path: '/distribution/ruleMange'
        //     },
        //     {
        //       name: '提现审核',
        //       auth: 'distributionWithdrawRecords',
        //       path: '/distribution/withdrawVerify'
        //     },
        //     {
        //       name: '排行榜',
        //       auth: 'rankDistribution',
        //       path: '/distribution/leaderboard'
        //     }
        //   ]
        // },
        {
          name: '统计管理',
          color: '#655bfe',
          icon: 'iconfinancial',
          auth:
            'statisticsManage,statisticsComposite,statisticsDeal,statisticsMemberRank,statisticsProductRank,statisticsVendorRank',
          children: [
            {
              name: '综合统计',
              auth: 'statisticsComposite',
              path: '/statistics/composite'
            },
            {
              name: '数据趋势',
              auth: 'statisticsComposite',
              path: '/statistics/dataTrend'
            },
            {
              name: '交易统计',
              auth: 'statisticsDeal',
              path: '/statistics/deal'
            },
            {
              name: '会员排行',
              auth: 'statisticsMemberRank',
              path: '/statistics/member'
            },
            {
              name: '商品排行',
              auth: 'statisticsProductRank',
              path: '/statistics/product'
            },
            {
              name: '商家排行',
              auth: 'statisticsVendorRank',
              path: '/statistics/vendor'
            },
            {
              name: '导出记录',
              auth: 'exportRecord',
              path: '/setting/export'
            }
          ]
        },
        {
          name: '财务管理',
          color: '#ffa000',
          icon: 'iconwallet',
          auth: 'financeManage,vendorFinancial,refundApply,refundApplyRecordList',
          children: [
            {
              name: '商家财务',
              auth: 'vendorFinancial',
              path: '/financial/vendor'
            },
            {
              name: '退款申请',
              auth: 'refundApply',
              path: '/financial/refundApply'
            },
            {
              name: '退款售后处理',
              auth: 'afterRefund',
              path: '/financial/afterRefund'
            },
            {
              name: '退款记录',
              auth: 'refundApplyRecordList',
              path: '/financial/refundApply/recordList'
            },
            {
              name: '提现申请',
              auth: 'paymentApply',
              path: '/financial/WithdrawApply'
            },
            {
              name: '提现申请记录',
              auth: 'paymentApply',
              path: '/financial/WithdrawApply/recordList'
            },
            {
              name: '营销人员数据',
              auth: 'salesWagesExportExcel',
              path: '/financial/salesWagesExportExcel'
            }
          ]
        },

        {
          name: '用户管理',
          color: '#3d89ff',
          icon: 'iconuser',
          auth: 'userManage,userList',
          children: [
            {
              name: '用户列表',
              auth: 'userList',
              path: '/user/list'
            }
          ]
        },

        {
          name: '设置',
          color: '#666',
          icon: 'iconsetting',
          auth:
            'settingPlatform,settingMall,settingContent,refundReasonManage,wxMediaPlatform,	areaSettingList,subMerchant,serviceTagManage,getDefaultRoleList,cooperation',
          children: [
            {
              name: '平台设置',
              auth:
                'settingPlatform,settingMall,settingContent,refundReasonManage,wxMediaPlatform,	areaSettingList,subMerchant,serviceTagManage,getDefaultRoleList,cooperation',
              path: '/setting/platform',
              children: [
                {
                  name: '服务标签',
                  auth: 'serviceTagManage,superAdmin',
                  path: '/setting/platform/serviceTag'
                },
                {
                  name: '商城设置',
                  auth: 'superAdmin,settingMall',
                  path: '/setting/platform/malls'
                },
                {
                  name: '内容设置',
                  auth: 'superAdmin,settingContent',
                  path: '/setting/platform/content'
                },
                {
                  name: '退款设置',
                  auth: 'superAdmin,refundReasonManage',
                  path: '/setting/platform/refund'
                },
                {
                  name: '公众号设置',
                  auth: 'superAdmin,wxMediaPlatform',
                  path: '/setting/platform/MediaPlatform'
                },
                {
                  name: '二级商户号设置',
                  auth: 'superAdmin,subMerchant',
                  path: '/setting/platform/twoLevelMerchant'
                },
                {
                  name: '地区设置',
                  auth: 'superAdmin,areaSettingList',
                  path: '/setting/platform/areaSetting'
                },
                {
                  name: '商家角色',
                  auth: 'superAdmin,getDefaultRoleList', // TODO: 还可以加一个权限
                  path: '/setting/platform/businessRoleList'
                },
                {
                  name: '商务设置',
                  auth: 'superAdmin,cooperation',
                  path: '/setting/platform/cooperation'
                }
              ]
            },
            {
              name: '账号管理',
              auth: 'sysUserManage,superAdmin',
              path: '/setting/accountMange/account'
            },
            {
              name: '日志记录',
              auth: 'sysLog',
              path: '/setting/log'
            }
          ]
        },
        {
          name: '应用中心',
          icon: 'iconmenu',
          auth: 'superAdmin',
          path: '/appCenter',
          icon: 'iconapplications',
          children: [
            {
              name: '小海豹',
              path: '/appCenter/photo-express'
            }
          ]
        }
      ],
      currentMenu: '',
      prevMenu: ''
    }
  },
  methods: {
    hightLineMenu() {
      this.$nextTick(() => {
        this.currentMenu = this.$route.path
      })
    }
  },
  watch: {
    $route() {
      this.hightLineMenu()
    }
  },
  mounted() {
    this.hightLineMenu()
  }
}
</script>

<style lang="less" scoped>
.sideBarContainer {
  width: 200px;
  z-index: 100;
  height: 100%;
  display: flex;
  flex-direction: column;
  .menuContainer {
    flex-grow: 1;
    flex-shrink: 0;
    padding: 20px;
  }
  .footer {
    border-top: 1px dashed @color-primary-bg;
    flex-grow: 0;
    flex-shrink: 0;
    padding: 10px;
    text-align: center;
    color: @color-assist;
    font-size: 12px;
  }
}

.icon {
  margin-right: 5px;
  width: 24px;
  text-align: center;
  font-size: 18px;
  height: 18px;
}
</style>
