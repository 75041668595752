<template>
  <div class="defaultLayoutContainer">
    <com-headerBar class="headerBar"></com-headerBar>
    <div class="mainContainer">
      <com-siderBar class="siderBar"></com-siderBar>
      <transition :name="hasTransition ? 'page-slide-right' : ''">
        <keep-alive :include="cache">
          <router-view class="innerView" />
        </keep-alive>
      </transition>
    </div>
  </div>
</template>

<script>
import comSiderBar from '@/component/layout/siderBar'
import comHeaderBar from '@/component/layout/headerBar'
export default {
  components: {
    comSiderBar,
    comHeaderBar
  },
  data() {
    return {
      cache: this.$store.state.global.cache
    }
  },
  computed: {
    hasTransition() {
      if (this.$route.meta.noTransition) {
        return false
      }
      return true
    }
  }
}
</script>

<style lang="less" scoped>
.defaultLayoutContainer {
  height: 100vh;
  width: 100vw;
  min-width: @minWidth;
  overflow: hidden;
}
.mainContainer {
  height: calc(~'100vh - ' @headerBar-height);
  display: flex;
  align-items: stretch;
  overflow: hidden;
  .siderBar {
    flex-shrink: 0;
    flex-grow: 0;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .innerView {
    flex-grow: 1;
    overflow-y: auto;
    background-color: #f7f7f7;
  }
}
</style>
