<template>
  <div>
    <div class="headerBarContainer">
      <span @click="$router.push('/')" class="logo" v-bg="logo"> </span>

      <div class="actionBar">
        <template v-if="userAreaInfoList.length <= 1">
          <div class="area-btn">
            {{ currentAreaId | formatArea }}
          </div>
        </template>
        <template v-else>
          <el-dropdown @command="handleAreaChange">
            <div class="area-btn">{{ currentAreaId | formatArea }}<i class="el-icon-arrow-down icon"></i></div>
            <el-dropdown-menu class="project-dropdown" slot="dropdown">
              <el-dropdown-item v-for="(item, n) in userAreaInfoList" :key="n" :command="item.id">
                {{ item.name }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
        <div style="width: 40px"></div>
        <!-- <div class="item" @click="$router.push('/account/profile')">
        <img class="icon avatar" :src="userInfo.avatar | thumbSmall" />
        <div class="title">{{ userInfo.name }}</div>
      </div> -->
        <el-dropdown :hide-on-click="false" @command="handleMenuChange">
          <div class="userinfo">
            <img class="avatar" :src="userInfo.avatar | thumbSmall" />
            <div class="name">{{ userInfo.name }}</div>
            <i class="el-icon-arrow-down icon"></i>
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="user">
              <img src="@/asset/icon/topbar/user.png" class="icon" />编辑资料</el-dropdown-item
            >
            <el-dropdown-item command="pw">
              <img src="@/asset/icon/topbar/pw.png" class="icon" />修改密码</el-dropdown-item
            >
            <el-dropdown-item command="logout">
              <img src="@/asset/icon/topbar/logout.png" class="icon" />退出登录</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>

        <!-- <div class="item" @click="handleLogout">
        <bm-icon class="logout" icon-class="iconlogout"></bm-icon>
        <div class="title">退出登陆</div>
      </div> -->
      </div>

      <!-- 添加商家门店，先搜索商家，点击商家，搜索门店，然后进行门店选择 -->
      <el-dialog :close-on-click-modal="false" title="测试使用的TOKEN" :visible.sync="showDialog">
        <div ref="copy">{{ token }}</div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="showDialog = false">关闭</el-button>
          <el-button @click="CopyUrl" type="primary">点击复制</el-button>
        </span>
      </el-dialog>
    </div>
    <slot></slot>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
export default {
  data() {
    return {
      userArea: parseInt(sessionStorage.areaId),
      showDialog: false,
      logo: require('@/asset/logo_head.png'),
      token: localStorage.token
    }
  },
  computed: {
    ...mapGetters({
      userInfo: 'user/userInfo',
      userAreaInfoList: 'user/userAreaInfoList',
      allAreaList: 'user/allAreaList',
      currentAreaId: 'user/currentAreaId'
    })
  },
  methods: {
    ...mapMutations({ updateCurrentAreaId: 'user/updateCurrentAreaId' }),
    ...mapActions({ logout: 'user/logout' }),
    handleAreaChange(val) {
      const loading = this.$loading({
        lock: true
      })
      this.updateCurrentAreaId(val)
      this.$router.push('/')
      this.$sleep(0.5).then(res => {
        loading.close()
        this.$message.success('切换地区成功')
        window.location.reload()
      })
    },
    handleMenuChange(v) {
      if (v === 'user') {
        this.$router.push('/account/profile')
      } else if (v === 'pw') {
        this.$router.push('/account/profile/password')
      } else {
        this.handleLogout()
      }
    },
    CopyUrl() {
      window.getSelection().removeAllRanges()
      const copyDOM = this.$refs.copy
      const range = document.createRange()
      range.selectNode(copyDOM)
      window.getSelection().addRange(range)
      try {
        let successful = document.execCommand('copy')
        this.$message.success('复制成功')
        this.showDialog = false
      } catch (err) {
        this.$message.error('复制失败')
      }
      window.getSelection().removeAllRanges()
    },
    handleLogout() {
      this.$confirm('确定要退出吗？')
        .then(() => {
          this.$store.commit('global/clearRouter')
          return this.logout()
        })
        .then(() => {
          this.$router.push('/account/login')
        })
    }
  },
  mounted() {
    this.updateCurrentAreaId(this.currentAreaId)
  }
}
</script>

<style lang="less" scoped>
.headerBarContainer {
  padding: 10px 0;
  padding-left: 5px;
  padding-right: 45px;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.el-dropdown-menu {
  background: #fff;
  border-radius: 12px;
  box-shadow: 2px 4px 20px -2px rgba(228, 228, 239, 0.6);
  border: none;
  padding: 12px 0;
  overflow: hidden;
  .el-dropdown-menu__item {
    color: rgba(71, 72, 73, 1);
    font-family: HarmonyOS Sans SC;
    font-size: 15px;
    padding: 6px 20px;
    letter-spacing: 1px;
    .icon {
      width: 16px;
      height: 16px;
      margin-right: 5px;
    }
  }
}
.logo {
  cursor: pointer;
  width: 200px;
  height: 36px;
  background-size: 65%;
}

.userinfo {
  width: fit-content;
  display: flex;
  align-items: center;
  cursor: pointer;
  .avatar {
    width: 36px;
    height: 36px;
    object-fit: cover;
    border-radius: 6px;
    margin-right: 10px;
  }
  .name {
    color: rgba(27, 29, 33, 1);
    font-family: HarmonyOS Sans SC;
    font-size: 15px;
  }
  .icon {
    width: 12px;
    height: 12px;
    color: rgba(27, 29, 33, 1);
    font-weight: bold;
    margin-left: 8px;
  }
}
.area-btn {
  background: none;
  border: none;
  color: rgba(27, 29, 33, 1);
  font-family: HarmonyOS Sans SC;
  font-size: 15px;
  display: flex;
  align-items: center;
  cursor: pointer;
  background: #fff;
  padding: 7px 20px;
  border-radius: 6px;
  &:hover {
    filter: brightness(95%);
  }
  .icon {
    width: 12px;
    height: 12px;
    color: rgba(27, 29, 33, 1);
    font-weight: bold;
    margin-left: 8px;
  }
}

.actionBar {
  display: flex;
  align-items: center;
  .area {
    width: 100px;
  }
  .item {
    .pointer;
    padding: 20px;
    display: flex;
    align-items: center;
    &:hover {
      background: @color-primary-bg;
      color: @color-primary-select;
    }

    .logout {
      margin-right: 5px;
      width: 24px;
      text-align: center;
      font-size: 18px;
      height: 18px;
    }
    .title {
      font-size: 14px;
    }
  }
}

.project-dropdown {
  max-height: 500px;
  overflow: auto;
}
</style>
