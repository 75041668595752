<template>
  <span class="debugText" @click="handleClick">{{ version }}</span>
</template>

<script>
import pkg from '../../package.json'
export default {
  data: () => ({
    clickCount: 0,
    version: process.env.VUE_APP_version || pkg.version
  }),
  computed: {},
  watch: {},
  methods: {
    handleClick() {
      this.clickCount++
      if (this.clickCount >= 5) {
        this.resetCount()
        this.jump()
      }
    },
    resetCount() {
      this.clickCount = 0
    },
    jump() {
      this.$router.push('/debug')
    }
  },
  created() {},
  mounted() {}
}
</script>

<style lang="less" scoped>
.debugText {
  cursor: pointer;
  -webkit-user-select: none;
}
</style>
